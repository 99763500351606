
body {
  background-color: #061222;
  color: #000000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.modal-content img {
  width: 100%;
  height: auto;
}


.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 95%;
  height: 100vh;
  margin: 0 auto;
  padding: 20px;
  background-image: url('./pexels-stein-egil-liland-3081835.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin-top: 20px;
  position: relative;
}

h1 {
  text-align: center;
  color: black;
  font-size: 4.5em;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.app-logo {
  height: 50px;
  padding-bottom: 1em;
}

hr.double {
  border-top: 3px double;
  border-bottom: none;
}

.card-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.card-column {
  gap: 1em;
  padding: 1em;
  padding-top: 3em;
}

.card {
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
  width: 280px;
  height: 150px; /* Set a fixed height for the cards */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Handle content overflow */
  margin-top: 1em;
  margin-bottom: 1em;
}

.card p {
  margin: 5px 0;
  font-size: 20px; /* Adjust font size for content */
}

.card:hover {
  background-color: #ffffff;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Styles for each card column */
.card-column {
  flex: 1; /* Each column takes equal space */
}

.button-container {
  display: flex;
  justify-content: center;
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 25px;
  font-size: 1.25em;
  cursor: pointer;
}

/* Media query for landscape orientation */
@media screen and (orientation: landscape) and (max-width: 1100px) {
  .app {
    width: 95%;
    height: 100vh;
  }
  .card-container {
    flex-direction: row; /* Ensure three columns layout */
    flex-wrap: wrap; /* Allow columns to wrap to new line if needed */
  }
  .card-column {
    flex: 0 0 calc(33.33% - 20px); /* Adjust column width */
  }
}

/* Media query for portrait orientation */
@media screen and (orientation: portrait) and (max-width: 800px) {
  .app {
    width: 95%;
    height: 100vh;
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Ensure three columns */
    justify-content: center; /* Center the grid horizontally */
  }
  .card {
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
    width: 200px;
    height: 170px; /* Set a fixed height for the cards */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden; /* Handle content overflow */
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
